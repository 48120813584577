import cookie from 'js-cookie'
// import localStorageUtil from './localStorage'

export const SessionWeb = (data) => {
    // AuthenticateUser(data);
    if (cookie.get('SESSION_BAYAGRI')) {
      const SavedDate = cookie.get('SESSION_BAYAGRI')
      const CuurentData = new Date()
      let diff = (CuurentData.getTime() - new Date(SavedDate).getTime()) / 1000
      diff /= (60 * 60)
      const hrs = Math.abs(Math.round(diff))
      console.log(`SESSION IS ABOUT TO EXPIRE IN ${24-hrs}hrs`)
      if (hrs <= 24) {
        const config = new Date()
        cookie.set('SESSION_BAYAGRI', config)
        cookie.set('SESSION_BAYAGRI_STATUS', 'ACTIVE')
        console.log("SESSION EXTENDED:::")
      } else {
        cookie.set('SESSION_BAYAGRI_STATUS', 'DEACTIVE')
        console.log("SESSION EXPIRED:::")
      }
  
      return hrs <= 24
    } else {
      const config = new Date()
      cookie.set('SESSION_BAYAGRI', config)
      cookie.set('SESSION_BAYAGRI_STATUS', 'ACTIVE')
      console.log(`SESSION CREATED AND WILL EXPIRE IN  ${24}hrs`)
      return true
    }
  }

//   const AuthenticateUser = (data) => {
//     const userData = localStorageUtil.getUserData()
//     if (data !== userData.userId) {
//       window.location.assign(`/dashboard`)
//     }
//   }